import { useEffect } from 'react';

export const useLockBodyScroll = (isLock: boolean) => {
  const noScrollClass = 'body--modal-open';

  useEffect(() => {
    if (isLock) {
      document.body.classList.add(noScrollClass);
    } else {
      document.body.classList.remove(noScrollClass);
    }

    return () => {
      document.body.classList.remove(noScrollClass);
    };
  }, [isLock]);
};
