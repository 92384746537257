import { setCookie } from 'nookies';
import { AppState } from '../index';
import { VacanciesSort } from '../../models/Vacancy';

const isSpecialTariffsViewed = typeof localStorage !== 'undefined' ? !!localStorage.getItem('settings_tariffs_viewed') : false;

export interface SettingsState {
  settings: {
    vacanciesSort: VacanciesSort;
    tariffsViewed?: boolean;
  };
  data: {
    openedModals: string[];
    hiddenElements: string[];
  };
}

const defaultSettingsState: SettingsState = {
  settings: {
    vacanciesSort: 'new',
    tariffsViewed: isSpecialTariffsViewed,
  },
  data: {
    openedModals: [],
    hiddenElements: [],
  },
};

// Actions
const MODAL_OPENED = 'marketplace/settings/modal-opened';
const MODAL_CLOSED = 'marketplace/settings/modal-closed';
const CLOSE_ALL_MODALS = 'marketplace/settings/close-all-modals';
const CHANGE_VACANCIES_SORT = 'marketplace/settings/vacancy-change-sort';
const SPECIAL_TARIFFS_VIEWED = 'marketplace/settings/special-tariffs-viewed';
const ADD_HIDDEN_ELEMENT = 'marketplace/settings/add-hidden-element';

// Reducer
export default function reducer(state: SettingsState = defaultSettingsState, action: any): SettingsState {
  switch (action.type) {
    case MODAL_OPENED:
      return {
        ...state,
        data: {
          ...state.data,
          openedModals: [...state.data.openedModals, action.modalId],
        },
      };
    case MODAL_CLOSED:
      return {
        ...state,
        data: {
          ...state.data,
          openedModals: state.data.openedModals.filter((modalId) => modalId !== action.modalId),
        },
      };
    case CLOSE_ALL_MODALS:
      return {
        ...state,
        data: {
          ...state.data,
          openedModals: [],
        },
      };
    case CHANGE_VACANCIES_SORT:
      setCookie(undefined, 'geecko_vacancies_sort', action.sort, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          vacanciesSort: action.sort,
        },
      };
    case SPECIAL_TARIFFS_VIEWED:
      setCookie(undefined, 'geecko_tariffs_viewed', '1', {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          tariffsViewed: true,
        },
      };
    case ADD_HIDDEN_ELEMENT:
      return {
        ...state,
        data: {
          ...state.data,
          hiddenElements: [...state.data.hiddenElements, action.elementId],
        },
      };
    default:
      return state;
  }
}

// Action creators
export const modalOpened = (modalId: string) => ({ type: MODAL_OPENED, modalId });
export const modalClosed = (modalId: string) => ({ type: MODAL_CLOSED, modalId });
export const closeAllModals = () => ({ type: CLOSE_ALL_MODALS });
export const changeVacanciesSort = (sort: VacanciesSort) => ({ type: CHANGE_VACANCIES_SORT, sort });
export const specialTariffsViewed = () => ({ type: SPECIAL_TARIFFS_VIEWED });
export const addHiddenElement = (elementId: string) => ({ type: ADD_HIDDEN_ELEMENT, elementId });

// Selectors
export const modalOpenedSelector = (state: AppState) => state.settings.data.openedModals;
export const vacanciesSortSelector = (state: AppState) => state.settings.settings.vacanciesSort;
export const specialTariffsViewedSelector = (state: AppState) => !!state.settings.settings.tariffsViewed;
export const isElementHiddenSelector = (state: AppState, elementId: string) =>
  state.settings.data.hiddenElements.indexOf(elementId) >= 0;
