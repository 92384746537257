import React, { FC, ReactNode, useEffect } from 'react';
import Head from 'next/head';
import { pageTitle } from 'models/helpers';
import { useSelector } from 'react-redux';
import { modalOpenedSelector } from 'store/ducks/settings';
import { useLockBodyScroll } from 'hooks/use-lock-body-scroll';
import zenscroll from 'zenscroll';

interface Props {
  title?: string;
  metaTags?: ReactNode | null;
}

const PageNext: FC<Props> = ({ title, children, metaTags }) => {
  const openedModals = useSelector(modalOpenedSelector);
  useLockBodyScroll(openedModals.length > 0);

  useEffect(() => {
    zenscroll.setup(300, 80);
  }, []);

  return (
    <>
      {title && (
        <Head>
          <title>{pageTitle(title)}</title>
          {metaTags}
        </Head>
      )}
      {children}
    </>
  );
};

export default PageNext;
